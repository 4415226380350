import { FragCompanyInfoItem } from '@/compositions/AboutBlock/query'
import { FragAccessMessagePageSection } from '@/compositions/AccessMessageBlock/query'
import { FragAttendedSessionsPageSection } from '@/compositions/AttendedSessionBlock/query'
import { FragBookMultipleSessionsSection } from '@/compositions/BookMultipleSessionsBlock/query'
import { FragCategoryClassesSection } from '@/compositions/CategoryClassesBlock/query'
import { FragCategoryIndexPageSection } from '@/compositions/CategoryIndexBlock/query'
import { FragCategoryInfoSection } from '@/compositions/CategoryInfoBlock/query'
import { FragCommunityBannerCard } from '@/compositions/CommunityBanners/query'
import { FragFeaturedClassesSection } from '@/compositions/FeaturedClassesBlock/query'
import { FragGuideClassesPageSection } from '@/compositions/GuideClassesBlock/query'
import { FragGuideIndexInfoPageSection } from '@/compositions/GuideIndexInfoBlock/query'
import { FragGuideInfoPageSection } from '@/compositions/GuidePageInfoBlock/query'
import { FragGuidePageSection } from '@/compositions/GuidePageSectionBlock/query'
import { FragHelpInfoSection } from '@/compositions/HelpInfoBlock/query'
import { FragHeroInfoPageSection } from '@/compositions/HeroInfoBlock/query'
import { FragLiveGuidesSection } from '@/compositions/LiveGuidesBlock/query'
import { FragMicroShortCard } from '@/compositions/MicroShortBlock/query'
import { FragSessionNotesDetailsPageSection } from '@/compositions/NotesDetailsBlock/query'
import { FragSessionNotesInfoPageSection } from '@/compositions/NotesInfoBlock/query'
import { FragPartnerInfoPageSection } from '@/compositions/PartnerInfoBlock/query'
import { FragPartnershipBannerSection } from '@/compositions/PartnershipBannerBlock/query'
import { FragPopularCategoriesSection } from '@/compositions/PopularCategoryBlock/query'
import { FragPopularSeriesSection } from '@/compositions/PopularSeriesBlock/query'
import { FragPricingPlanSection } from '@/compositions/PricingBlock/query'
import { FragScheduleDay } from '@/compositions/ScheduleBlock/query'
import { FragSeriesPageSection } from '@/compositions/SeriesBlock/query'
import { FragSeriesClassesPageSection } from '@/compositions/SeriesClassesBlock/query'
import { FragSeriesClassesRowSection } from '@/compositions/SeriesClassesRowBlock/query'
import { FragSeriesIndexInfoPageSection } from '@/compositions/SeriesIndexInfoBlock/query'
import { FragSeriesInfoPageSection } from '@/compositions/SeriesInfoBlock/query'
import { FragSnippetSection } from '@/compositions/SnippetBlock/query'
import { FragSponsorInfoPageSection } from '@/compositions/SponsorInfoBlock/query'
import { FragSessionCard } from '@/widgets/ClassCard/query'
import { FragGuideCard } from '@/widgets/InstructorCard/query'
import { FragQuoteCard } from '@/widgets/QuoteCard/query'
import { FragSeriesCard } from '@/widgets/SeriesCard/query'
import { gql } from 'urql'
import { FragImagePageSection } from '../ImageBlock/query'

export const FragComposerSections = gql`
  ${FragAttendedSessionsPageSection}
  ${FragSessionCard}
  ${FragSeriesCard}
  ${FragQuoteCard}
  ${FragCompanyInfoItem}
  ${FragScheduleDay}
  ${FragPartnerInfoPageSection}
  ${FragFeaturedClassesSection}
  ${FragSeriesClassesPageSection}
  ${FragSeriesClassesRowSection}
  ${FragSeriesInfoPageSection}
  ${FragHeroInfoPageSection}
  ${FragSeriesIndexInfoPageSection}
  ${FragSeriesPageSection}
  ${FragPopularSeriesSection}
  ${FragLiveGuidesSection}
  ${FragSnippetSection}
  ${FragHelpInfoSection}
  ${FragPricingPlanSection}
  ${FragGuideInfoPageSection}
  ${FragGuideIndexInfoPageSection}
  ${FragGuidePageSection}
  ${FragGuideClassesPageSection}
  ${FragGuideCard}
  ${FragBookMultipleSessionsSection}
  ${FragCategoryIndexPageSection}
  ${FragPopularCategoriesSection}
  ${FragCategoryInfoSection}
  ${FragCategoryClassesSection}
  ${FragAccessMessagePageSection}
  ${FragImagePageSection}
  ${FragCommunityBannerCard}
  ${FragSessionNotesInfoPageSection}
  ${FragSessionNotesDetailsPageSection}
  ${FragMicroShortCard}
  ${FragSponsorInfoPageSection}
  ${FragPartnershipBannerSection}

  fragment CoreSections on PageSection {
    __typename
    id
    subtitle
    title
    ... on FeaturedSessionsSection {
      items {
        ...SessionCard
      }
    }
    ... on CategorySessionsSection {
      items {
        ...SessionCard
      }
    }
    ... on LiveAndUpcomingClassesPageSection {
      items {
        ...SessionCard
      }
    }
    ... on PricingPageSection {
      ...PricingPageSection
    }
    ... on MostPopularPageSection {
      items {
        ...SessionCard
      }
    }
    ... on SimilarClassesPageSection {
      items {
        ...SessionCard
      }
    }
    ... on SearchPageSection {
      items {
        ...SessionCard
      }
    }
    ... on SchedulePageSection {
      items {
        ...ScheduleDay
      }
    }
    ... on UpcomingPageSection {
      classId
      displayPrice {
        currencyCode
        currencySymbol
        amount
      }
      displayPriceDecoration
      items {
        ...SessionCard
      }
    }
    ... on NextSessionSection {
      classId
      items {
        ...SessionCard
      }
    }
    ... on TestimonialsPageSection {
      quoteItems: items {
        ...QuoteCard
      }
    }
    ... on CompanyInfoPageSection {
      infoItems: items {
        ...CompanyInfoItem
      }
    }
    ... on CommunityBannersSection {
      bannerItems: items {
        ...CommunityBannerCard
      }
    }
    ... on MicroLearningShortsSection {
      videoItems: items {
        ...MicroShortCard
      }
    }
    # TODO: Co-locate with Image component when ready
    ... on ImagePageSection {
      ...ImagePageSection
    }
    # TODO: Co-locate with Content component when ready
    ... on ContentPageSection {
      navigationActions {
        ... on SectionNavigationAction {
          label
          targetPageId
          theme
        }
      }
      content {
        ... on TextContent {
          text
          html
        }
      }
    }
    ... on SeriesIndexInfoPageSection {
      ...SeriesIndexInfoPageSection
    }
    ... on GuideIndexInfoPageSection {
      ...GuideIndexInfoPageSection
    }
    ... on GuidePageSection {
      ...GuidePageSection
    }
    ... on GuideClassesPageSection {
      ...GuideClassesPageSection
    }
    ... on GuideSessionsPageSection {
      items {
        ...SessionCard
      }
    }
    ... on GuideInfoPageSection {
      ...GuideInfoPageSection
    }
    ... on PartnerInfoPageSection {
      ...PartnerInfoPageSection
    }
    ... on PopularSeriesSection {
      ...PopularSeriesSection
    }
    ... on SponsorInfoPageSection {
      ...SponsorInfoPageSection
    }
    ... on PopularCategoriesSection {
      ...PopularCategoriesSection
    }
    ... on CategoryIndexPageSection {
      ...CategoryIndexPageSection
    }
    ... on CategoryInfoSection {
      ...CategoryInfoSection
    }
    ... on CategoryClassesSection {
      ...CategoryClassesSection
    }
    ... on LiveGuidesSection {
      ...LiveGuidesSection
    }
    ... on SeriesPageSection {
      ...SeriesPageSection
    }
    ... on FeaturedClassesSection {
      ...FeaturedClassesSection
    }
    ... on BookMultipleSessionsSection {
      ...BookMultipleSessionsSection
    }
    ... on HeroInfoSection {
      ...HeroInfoSection
    }
    ... on AccessMessageSection {
      ...AccessMessageSection
    }
    ... on SeriesClassesPageSection {
      ...SeriesClassesPageSection
    }
    ... on SeriesClassesRowSection {
      ...SeriesClassesRowSection
    }
    ... on SeriesInfoPageSection {
      ...SeriesInfoPageSection
    }
    ... on SnippetPageSection {
      ...SnippetSection
    }
    ... on HelpInfoPageSection {
      ...HelpInfoSection
    }
    ... on AttendedSessionsPageSection {
      ...AttendedSessionsPageSection
    }
    ... on SessionNotesInfoPageSection {
      ...SessionNotesInfoPageSection
    }
    ... on SessionNotesDetailsPageSection {
      ...SessionNotesDetailsPageSection
    }
    # TODO: Co-locate with CTA component when ready
    ... on CTAPageSection {
      url
      label
    }
    # TODO: Co-locate with ClassSummary component
    ... on ClassSummaryPageSection {
      classSummaryContent: content {
        ... on ClassSummaryContent {
          id
          title
          subtitle
          rating
          reviewCount
          tags {
            ... on Tag {
              value
            }
          }
          durationMinutes
          guideName
          guideAvatarUrl
          guideLocation
          navigateToGuideAction {
            url
          }
        }
      }
    }
    # TODO: Co-locate with TagsSection component
    ... on TagsPageSection {
      tagItems: items {
        # TODO: Co-locate with Tag component
        ... on Tag {
          value
        }
      }
    }
    # TODO: Co-locate with ReviewSection
    ... on ReviewPageSection {
      reviewItems: items {
        # TODO: Co-locate with Review component
        ... on Review {
          content
          date
          id
          rating
          subtitle
          title
        }
      }
    }
    # TODO: Co-locate with GuidePageSection
    ... on GuideClassPageSection {
      content {
        id
        name
        slug
        tagline
        active
        askMeAbout
        avatarUrl
        facebookUrl
        featured
        linkedInUrl
        location
        title
        twitterUrl
      }
    }
    ... on PartnershipBannerSection {
      ...PartnershipBannerSection
    }
  }
  # Document 3 levels deep, any further depth will be ignored
  # since the query won't pull the entire tree - This is deliberate
  # to avoid potential performance issues, and can be addressed
  # later if we need to
  fragment RecursiveSections on PageSection {
    ... on SectionGroupPageSection {
      layoutHint
      sections {
        ...CoreSections
        ... on SectionGroupPageSection {
          layoutHint
          sections {
            ...CoreSections
            ... on SectionGroupPageSection {
              layoutHint
              sections {
                ...CoreSections
              }
            }
          }
        }
      }
    }
  }
`
